<!-- eslint-disable -->
<template>
  <div class='page_box'>
    <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
    <div class="module_box">
      <el-descriptions :column="2">
        <template slot="title">
          <div class="module_title">
            <div class="title_line"></div>
            <div>基本信息</div>
          </div>
        </template>
        <el-descriptions-item labelClassName="d_title" label="拼团ID">{{ detailInfo.groupActivityId || '-' }}</el-descriptions-item>
        <el-descriptions-item labelClassName="d_title" label="拼团状态">{{ detailInfo.statusStr || '-' }}</el-descriptions-item>
        <el-descriptions-item labelClassName="d_title" label="团长昵称">{{ detailInfo.nickName || '-' }}</el-descriptions-item>
        <el-descriptions-item labelClassName="d_title" label="团长手机号">{{ detailInfo.userMobile || '-' }}</el-descriptions-item>
        <el-descriptions-item labelClassName="d_title" label="开团时间">{{ detailInfo.startTime || '-' }}</el-descriptions-item>
        <el-descriptions-item labelClassName="d_title" label="结束时间">{{ detailInfo.endTime || '-' }}</el-descriptions-item>
        <el-descriptions-item labelClassName="d_title" label="当前人数/成团人数">{{ detailInfo.joinNum }}/{{ detailInfo.groupNum }}</el-descriptions-item>
        <el-descriptions-item labelClassName="d_title" label="自动成团">{{ detailInfo.hasRobotStr || '-' }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="module_box">
      <div class="module_title">
        <div class="title_line"></div>
        <div>拼团服务</div>
      </div>
      <div class="module_table">
        <el-table
          :data="detailInfo.cardTemplateList"
          :header-cell-style="{
            borderColor: '#EBEEF5',
            background: '#FAFAFA',
            color: '#333',
            fontWeight: 'normal'
          }"
          size="mini"
          border
          style="width: 100%">
          <el-table-column prop="cardTemplateCode" label="卡编号" min-width="180"></el-table-column>
          <el-table-column prop="cardTemplateName" label="对应卡" min-width="180"></el-table-column>
          <el-table-column prop="validPeriod" label="卡有效期" min-width="180">
            <template slot-scope="scope">
              <div>{{ scope.row.validPeriod == -1?'永久': (scope.row.validPeriod == -2 ? '截至到 '+scope.row.validTimeEnd : scope.row.validPeriod + '天')  }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="cardAmount" label="金额" min-width="180"></el-table-column>
          <el-table-column prop="carryingAmount" label="账面金额" min-width="180"></el-table-column>
          <el-table-column prop="storeTypesName" label="适用门店" min-width="180"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="module_box">
      <div class="module_title">
        <div class="title_line"></div>
        <div>拼团订单</div>
      </div>
      <div class="module_table">
        <el-table
          :data="detailInfo.orderList"
          :header-cell-style="{
            borderColor: '#EBEEF5',
            background: '#FAFAFA',
            color: '#333',
            fontWeight: 'normal'
          }"
          size="mini"
          border
          style="width: 100%">
          <el-table-column prop="id" label="ID" min-width="80"></el-table-column>
          <el-table-column prop="nickName" label="参团人" min-width="180"></el-table-column>
          <el-table-column prop="date" label="订单编号" min-width="180">
            <template slot-scope="scope">
              <el-button type="text" size="mini">{{ scope.row.groupOrderNo }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="ctime" label="下单时间" min-width="180"></el-table-column>
          <el-table-column prop="paymentTime" label="支付时间" min-width="180"></el-table-column>
          <el-table-column prop="payAmount" label="下单金额" min-width="180"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        serveList: [],
        orderList: [],
        detailInfo: {},
      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "拼团记录", path: "/management/groupBuying/groupOrder/list" },
        {
          name: "拼团详情"
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 获取详情
    getDetail(groupTeamId){
      this.$store.dispatch('management/groupOrderDetail',{
        groupTeamId
      }).then(res => {
        this.detailInfo = res.data
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getDetail(this.$route.query.id)
  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    .module_box{
      padding: 20px;
      box-sizing: border-box;
      background: #fff;
      margin-top: 20px;
      .module_title{
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #262626;
        .title_line{
          width: 4px;
          height: 20px;
          background: #409EFF;
          margin-right: 5px;
          border-radius: 1px;
        }
      }
      .module_table{
        margin-top: 20px;
      }
      ::v-deep .d_title{
        color: #999 !important;
      }
    }
  }
  ::v-deep .el-descriptions{
    width: 50% !important;
  }
</style>