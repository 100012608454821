var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_box"},[_c('se-breadcrumb',{attrs:{"itemData":_vm.breadData,"showBottomBorder":""}}),_c('div',{staticClass:"module_box"},[_c('el-descriptions',{attrs:{"column":2}},[_c('template',{slot:"title"},[_c('div',{staticClass:"module_title"},[_c('div',{staticClass:"title_line"}),_c('div',[_vm._v("基本信息")])])]),_c('el-descriptions-item',{attrs:{"labelClassName":"d_title","label":"拼团ID"}},[_vm._v(_vm._s(_vm.detailInfo.groupActivityId || '-'))]),_c('el-descriptions-item',{attrs:{"labelClassName":"d_title","label":"拼团状态"}},[_vm._v(_vm._s(_vm.detailInfo.statusStr || '-'))]),_c('el-descriptions-item',{attrs:{"labelClassName":"d_title","label":"团长昵称"}},[_vm._v(_vm._s(_vm.detailInfo.nickName || '-'))]),_c('el-descriptions-item',{attrs:{"labelClassName":"d_title","label":"团长手机号"}},[_vm._v(_vm._s(_vm.detailInfo.userMobile || '-'))]),_c('el-descriptions-item',{attrs:{"labelClassName":"d_title","label":"开团时间"}},[_vm._v(_vm._s(_vm.detailInfo.startTime || '-'))]),_c('el-descriptions-item',{attrs:{"labelClassName":"d_title","label":"结束时间"}},[_vm._v(_vm._s(_vm.detailInfo.endTime || '-'))]),_c('el-descriptions-item',{attrs:{"labelClassName":"d_title","label":"当前人数/成团人数"}},[_vm._v(_vm._s(_vm.detailInfo.joinNum)+"/"+_vm._s(_vm.detailInfo.groupNum))]),_c('el-descriptions-item',{attrs:{"labelClassName":"d_title","label":"自动成团"}},[_vm._v(_vm._s(_vm.detailInfo.hasRobotStr || '-'))])],2)],1),_c('div',{staticClass:"module_box"},[_vm._m(0),_c('div',{staticClass:"module_table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.detailInfo.cardTemplateList,"header-cell-style":{
          borderColor: '#EBEEF5',
          background: '#FAFAFA',
          color: '#333',
          fontWeight: 'normal'
        },"size":"mini","border":""}},[_c('el-table-column',{attrs:{"prop":"cardTemplateCode","label":"卡编号","min-width":"180"}}),_c('el-table-column',{attrs:{"prop":"cardTemplateName","label":"对应卡","min-width":"180"}}),_c('el-table-column',{attrs:{"prop":"validPeriod","label":"卡有效期","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(_vm._s(scope.row.validPeriod == -1?'永久': (scope.row.validPeriod == -2 ? '截至到 '+scope.row.validTimeEnd : scope.row.validPeriod + '天')))])]}}])}),_c('el-table-column',{attrs:{"prop":"cardAmount","label":"金额","min-width":"180"}}),_c('el-table-column',{attrs:{"prop":"carryingAmount","label":"账面金额","min-width":"180"}}),_c('el-table-column',{attrs:{"prop":"storeTypesName","label":"适用门店","min-width":"180"}})],1)],1)]),_c('div',{staticClass:"module_box"},[_vm._m(1),_c('div',{staticClass:"module_table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.detailInfo.orderList,"header-cell-style":{
          borderColor: '#EBEEF5',
          background: '#FAFAFA',
          color: '#333',
          fontWeight: 'normal'
        },"size":"mini","border":""}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID","min-width":"80"}}),_c('el-table-column',{attrs:{"prop":"nickName","label":"参团人","min-width":"180"}}),_c('el-table-column',{attrs:{"prop":"date","label":"订单编号","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"mini"}},[_vm._v(_vm._s(scope.row.groupOrderNo))])]}}])}),_c('el-table-column',{attrs:{"prop":"ctime","label":"下单时间","min-width":"180"}}),_c('el-table-column',{attrs:{"prop":"paymentTime","label":"支付时间","min-width":"180"}}),_c('el-table-column',{attrs:{"prop":"payAmount","label":"下单金额","min-width":"180"}})],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module_title"},[_c('div',{staticClass:"title_line"}),_c('div',[_vm._v("拼团服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module_title"},[_c('div',{staticClass:"title_line"}),_c('div',[_vm._v("拼团订单")])])
}]

export { render, staticRenderFns }